<template>
  <div class="shipping-settings-view">
    <async-object
      should-fetch
      :fetch-method="fetchData"
    >
      <div class="card mb-4">
        <div class="card-body">
          <v-dynamic-form
            :fields="fields"
            :initial-values="form"
            @change="handleChange"
            :errors="errors"
          ></v-dynamic-form>
        </div>
      </div>

      <template v-if="form.shippingPricePolicy === 'fixedAmountPerItem' || form.shippingPricePolicy === 'amountPerDollarSpent'">
        <div class="card mb-4" v-for="(tier, index) in tiers" :key="tier.number">
          <div class="card-header">Palier {{ index + 1 }}</div>
          <div class="card-body">
            <div class="form-group row">
              <label :for="`tier${tier.number}-quantity`" class="col-sm-3 col-form-label">A partir de</label>
              <div class="col-sm-5">
                <div class="input-group mb-3">
                  <input type="number" class="form-control" :id="`tier${tier.number}-quantity`" v-model.number="tier.quantity">
                  <div class="input-group-append">
                    <span v-if="form.shippingPricePolicy === 'fixedAmountPerItem'" class="input-group-text">articles</span>
                    <span v-if="form.shippingPricePolicy === 'amountPerDollarSpent'" class="input-group-text">$</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label :for="`tier${tier.number}-amount`" class="col-sm-3 col-form-label">Charger un montant de</label>
              <div class="col-sm-5">
                <div class="input-group mb-3">
                  <input type="number" class="form-control" :id="`tier${tier.number}-amount`" v-model.number="tier.amount">
                  <div class="input-group-append">
                    <span v-if="form.shippingPricePolicy === 'fixedAmountPerItem'" class="input-group-text">$ / article supplémentaire</span>
                    <span v-if="form.shippingPricePolicy === 'amountPerDollarSpent'" class="input-group-text">$</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-right">
              <a href="" class="text-danger" @click.prevent="removeTier(tier.number)">Retirer</a>
            </div>
          </div>
        </div>

        <div class="mb-4" v-if="tiers.length < 5">
          <a href="" @click.prevent="addTier">
            <fa-icon :icon="['fas', 'plus']" class="mr-1"></fa-icon>
            Ajouter un palier
          </a>
        </div>
      </template>

      <button type="button" class="btn btn-primary" :disabled="isProcessing" @click="submit">Enregistrer</button>
    </async-object>
  </div>
</template>

<script>
import { find, pick } from 'lodash-es'
import http from '@/utils/http'
import userData from '@/mixins/user-data'
import handleForm from '@/mixins/handle-form'
import useValidator from '@/mixins/useValidator'
import AsyncObject from '@/components/common/AsyncObject'

export default {
  mixins: [userData, handleForm, useValidator],
  components: {
    AsyncObject
  },
  data () {
    return {
      form: {
        shippingPricePolicy: 'free',
        shippingPriceAmount: '',
        freeShippingThreshold: ''
      },
      tiers: [],
      isProcessing: false
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'shippingPricePolicy',
          label: 'Politique de frais de port ',
          type: 'select',
          options: [
            {
              value: 'free',
              label: 'Livraison gratuite'
            },
            {
              value: 'fixedAmount',
              label: 'Montant fixe par commande'
            },
            {
              value: 'fixedAmountPerItem',
              label: 'Montant variable par article'
            },
            {
              value: 'amountPerDollarSpent',
              label: 'Montant par tranche de dollar dépensé'
            }
          ],
          required: true
        },
        {
          name: 'shippingPriceAmount',
          label: 'Montant des frais',
          type: 'number',
          required: true,
          isVisible: (values) => values.shippingPricePolicy === 'fixedAmount' || values.shippingPricePolicy === 'fixedAmountPerItem' || values.shippingPricePolicy === 'amountPerDollarSpent'
        },
        {
          name: 'freeShippingThreshold',
          label: "Offrir les frais à partir d'un montant de :",
          type: 'number',
          isVisible: (values) => values.shippingPricePolicy === 'fixedAmount' || values.shippingPricePolicy === 'fixedAmountPerItem' || values.shippingPricePolicy === 'amountPerDollarSpent'
        }
      ]
    },
    validationRules () {
      return {
        shippingPricePolicy: 'required',
        shippingPriceAmount: 'required_if:shippingPricePolicy,fixedAmount|required_if:shippingPricePolicy,fixedAmountPerItem|required_if:shippingPricePolicy,amountPerDollarSpent'
      }
    }
  },
  methods: {
    async fetchData () {
      const { data } = await http.get(`/v1/stores/${this.currentStoreId}/settings`)

      this.form = pick({
        ...data
      }, ['shippingPricePolicy', 'shippingPriceAmount', 'freeShippingThreshold'])

      this.initTiersFromFetchedSettings(data)
    },
    initTiersFromFetchedSettings (data) {
      for (let i = 1; i <= 5; i += 1) {
        const quantityKey = `tier${i}Quantity`
        const amountKey = `tier${i}Amount`

        if (data[quantityKey]) {
          this.tiers.push({
            number: i,
            quantity: data[quantityKey],
            amount: data[amountKey]
          })
        }
      }
    },
    handleChange (values) {
      this.form = { ...values }
    },
    async submit () {
      const isValid = this.validate(this.form, this.validationRules)

      if (!isValid) {
        return
      }

      this.isProcessing = true

      const payload = this.getPayloadByPolicy(this.form)

      try {
        await http.put(`/v1/stores/${this.currentStoreId}/settings`, payload)

        this.$toasted.success('Paramètres enregistrés avec succès.')
        this.isProcessing = false
      } catch (e) {
        console.error('Error', e)
        this.isProcessing = false
        this.$toasted.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    },
    getPayloadByPolicy (form) {
      if (form.shippingPricePolicy === 'fixedAmount') {
        return { ...form, ...this.getEmptyTierProperties() }
      }

      if (form.shippingPricePolicy === 'fixedAmountPerItem' || form.shippingPricePolicy === 'amountPerDollarSpent') {
        return { ...form, ...this.getTierProperties() }
      }

      // Default case: free shipping

      return {
        shippingPricePolicy: 'free',
        shippingPriceAmount: null,
        freeShippingThreshold: null,
        ...this.getEmptyTierProperties()
      }
    },
    getEmptyTierProperties () {
      return {
        tier1Quantity: '',
        tier1Amount: '',
        tier2Quantity: '',
        tier2Amount: '',
        tier3Quantity: '',
        tier3Amount: '',
        tier4Quantity: '',
        tier4Amount: '',
        tier5Quantity: '',
        tier5Amount: ''
      }
    },
    getTierProperties () {
      const tiers = this.getEmptyTierProperties()
      let number = 1

      for (const tier of this.tiers) {
        if (tier.quantity) {
          tiers[`tier${number}Quantity`] = tier.quantity
          tiers[`tier${number}Amount`] = tier.amount
          number += 1
        }
      }

      return tiers
    },
    addTier () {
      if (this.tiers.length >= 5) {
        return
      }

      this.tiers.push({
        number: this.tiers.length + 1,
        quantity: '',
        amount: ''
      })
    },
    removeTier (number) {
      const tier = find(this.tiers, { number })
      const index = tier ? this.tiers.indexOf(tier) : -1

      if (index > -1) {
        this.tiers.splice(index, 1)
      }
    }
  }
}
</script>
